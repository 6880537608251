import { ExportModal, ProgrammeComponent } from 'egenie-utils';
import React from 'react';
import { Store } from './store';

const store = new Store();

export default function() {
  return (
    <>
      <ProgrammeComponent store={store.programme}/>
      <ExportModal store={store.exportStore}/>
    </>
  );
}

