import type { FilterConfigData } from 'egenie-utils';
import { ExportStore, formatFilterConfigData, Programme, request } from 'egenie-utils';
import React from 'react';
import { getWarehouseArea, getWarehouseBin } from '../../utils';
import { filterItems } from './filterItems';
import { mainSubStructureModel } from './mainSubStructureModel';

export class Store {
  constructor() {
    getWarehouseArea()
      .then((data) => this.programme.filterItems.addDict({
        srcWarehouseAreaId: data,
        destWarehouseAreaId: data,
      }));
    getWarehouseBin()
      .then((data) => this.programme.filterItems.addDict({
        srcWarehouseBinId: data,
        destWarehouseBinId: data,
      }));

    request<FilterConfigData>({
      url: '/api/cloud/baseinfo/rest/filterSet2/getConfig2',
      params: {
        itemList: 'warehouse',
        dictList: 'wms_shift_bin_order_state',
        module: '',
      },
    })
      .then((info) => {
        const list = formatFilterConfigData(info, {
          wms_shift_bin_order_state: 'orderState',
          warehouse: 'warehouseId',
        });
        this.programme.filterItems.addDict(list.reduce((prev, current) => ({
          ...prev,
          [current.field]: current.data,
        }), {}));
      });
  }

  public exportStore: ExportStore = new ExportStore({ parent: null });

  public programme: Programme = new Programme({
    gridModel: mainSubStructureModel(this),
    filterItems: filterItems(this),
    moduleName: 'WMSReceiveOrders',
  });
}
