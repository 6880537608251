import type { FilterItemOptions, FilterSelect } from 'egenie-utils';
import { getWarehouseArea, getWarehouseBin } from '../../utils';
import type { Store } from './store';

export function filterItems(context: Store): FilterItemOptions[] {
  return [
    {
      type: 'date',
      field: 'dateType',
      label: '日期类型',
      selectValue: '0',
      data: [
        {
          value: '0',
          label: '创建时间',
        },
      ],
    },
    {
      type: 'select',
      label: '审核状态',
      field: 'orderState',
      data: [],
    },
    {
      mode: 'multiple',
      type: 'select',
      label: '仓库',
      field: 'warehouseId',
      data: [],
      onChangeCallback: (warehouseId: string[]) => {
        const srcWarehouseAreaItem = context.programme.filterItems.getFilterItem('srcWarehouseAreaId') as FilterSelect;
        srcWarehouseAreaItem.value = [];
        srcWarehouseAreaItem.handleData([]);

        const destWarehouseAreaItem = context.programme.filterItems.getFilterItem('destWarehouseAreaId') as FilterSelect;
        destWarehouseAreaItem.value = [];
        destWarehouseAreaItem.handleData([]);

        const srcWarehouseBinItem = context.programme.filterItems.getFilterItem('srcWarehouseBinId') as FilterSelect;
        srcWarehouseBinItem.value = [];
        srcWarehouseBinItem.handleData([]);

        const destWarehouseBinItem = context.programme.filterItems.getFilterItem('destWarehouseBinId') as FilterSelect;
        destWarehouseBinItem.value = [];
        destWarehouseBinItem.handleData([]);

        getWarehouseArea({ warehouseId: warehouseId ? warehouseId.join(',') : undefined })
          .then((data) => {
            srcWarehouseAreaItem.handleData(data);
            destWarehouseAreaItem.handleData(data);
          });

        getWarehouseBin({ warehouseId: warehouseId ? warehouseId.join(',') : undefined })
          .then((data) => {
            srcWarehouseBinItem.handleData(data);
            destWarehouseBinItem.handleData(data);
          });
      },
    },
    {
      type: 'select',
      field: 'srcWarehouseAreaId',
      mode: 'multiple',
      label: '原库区',
      onChangeCallback: (warehouseAreaId: string[]) => {
        const warehouseItem = context.programme.filterItems.getFilterItem('warehouseId') as FilterSelect;
        const srcWarehouseBinItem = context.programme.filterItems.getFilterItem('srcWarehouseBinId') as FilterSelect;
        srcWarehouseBinItem.value = [];
        srcWarehouseBinItem.handleData([]);

        getWarehouseBin({
          warehouseId: warehouseItem.value ? (warehouseItem.value as string[]).join(',') : undefined,
          warehouseAreaId: warehouseAreaId ? warehouseAreaId.join(',') : undefined,
        })
          .then((data) => srcWarehouseBinItem.handleData(data));
      },
    },
    {
      type: 'select',
      mode: 'multiple',
      field: 'srcWarehouseBinId',
      label: '原库位',
    },
    {
      type: 'select',
      field: 'destWarehouseAreaId',
      mode: 'multiple',
      label: '目标库区',
      onChangeCallback: (warehouseAreaId: string[]) => {
        const warehouseItem = context.programme.filterItems.getFilterItem('warehouseId') as FilterSelect;
        const destWarehouseBinItem = context.programme.filterItems.getFilterItem('destWarehouseBinId') as FilterSelect;
        destWarehouseBinItem.value = [];
        destWarehouseBinItem.handleData([]);

        getWarehouseBin({
          warehouseId: warehouseItem.value ? (warehouseItem.value as string[]).join(',') : undefined,
          warehouseAreaId: warehouseAreaId ? warehouseAreaId.join(',') : undefined,
        })
          .then((data) => destWarehouseBinItem.handleData(data));
      },
    },
    {
      type: 'select',
      mode: 'multiple',
      field: 'destWarehouseBinId',
      label: '目标库位',
    },
    {
      type: 'input',
      label: '单据编号',
      field: 'shiftBinNo',
    },
    {
      type: 'input',
      label: 'sku编码',
      field: 'skuNo',
    },
    {
      type: 'input',
      label: '条形码',
      field: 'barCode',
    },
    {
      type: 'input',
      label: '波次号',
      field: 'wmsPickingOrderNo',
    },
  ];
}
