import { Modal } from 'antd';
import type { PaginationData } from 'egenie-utils';
import { MainSubStructureModel, request } from 'egenie-utils';
import { values } from 'mobx';
import React from 'react';
import { logSubGrid } from '../../utils';
import { stockShiftBinGoodsDetail } from './stockShiftBinGoodsDetail';
import type { Store } from './store';
import type { MainItem } from './types';

export function mainSubStructureModel(context: Store): MainSubStructureModel {
  return new MainSubStructureModel({
    pageId: '1637',
    buttons: [
      {
        permissionId: '6',
        text: '导出',
        icon: 'icon-export',
        handleClick: () => {
          const ids = values(context.programme.gridModel.gridModel.selectedIds).join(',');
          const fileName = '移库单';
          const exportType = 'cloud_wms_shift_bin';
          const queryParam = context.programme.filterItems.params;
          const queryParamShow = context.programme.filterItems.translateParams.join(' ');

          if (ids) {
            context.exportStore.onShow(fileName, exportType, ids, queryParam, queryParamShow);
          } else {
            Modal.confirm({
              title: '提示',
              content: '未选择数据将导出全部数据?',
              onOk: () => {
                context.exportStore.onShow(fileName, exportType, '', queryParam, queryParamShow);
              },
            });
          }
        },
      },
    ],
    grid: {
      columns: [
        {
          key: 'orderState',
          name: '审核状态',
          width: 80,
        },
        {
          key: 'wmsShiftBinOrderNo',
          name: '单据编号',
          width: 150,
        },
        {
          key: 'checkerName',
          name: '审核人',
          width: 150,
        },
        {
          key: 'warehouseName',
          name: '仓库',
          width: 150,
        },
        {
          key: 'createTime',
          name: '创建时间',
          width: 150,
          sortable: true,
        },
        {
          key: 'authTime',
          name: '审核时间',
          width: 150,
          sortable: true,
        },
        {
          key: 'creatorName',
          name: '创建人',
          width: 150,
        },
        {
          key: 'wmsPickingOrderNo',
          name: '波次号',
          width: 200,
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'wmsShiftBinOrderId',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/stockShiftBin/index/main',
    },

    // 主表查询api
    api: {
      onQuery: (params) => {
        const {
          filterParams,
          ...rest
        } = params;
        return request<PaginationData<MainItem>>({
          url: '/api/cloud/wms/rest/shiftbin/list',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
          },
        });
      },
    },
    subTables: {
      activeTab: 'detail',
      tabsFlag: {
        inited: {
          detail: true,
          log: false,
        },
        searched: {},
      },
      list: [
        stockShiftBinGoodsDetail(),
        logSubGrid(30000),
      ],
    },
  });
}
